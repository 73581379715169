import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sitemap from "../components/sitemap/sitemap"

const SitemapPage = () => (
  <Layout>
    <SEO title="Sitemap | Techvizon" pathname="/sitemap" description="Get around our website" />
    <Sitemap/> 
  </Layout>
)

export default SitemapPage
