import React from 'react'
import "./sitemap.css"
import {Link} from 'gatsby'
const Sitemap = () => {
    return (
        <div className="sitemap-wrapper">
        <div className="container py-5">
            <div className="row mx-auto">
                <div className="col-md-12 col-sm-6 sitemap-adjust mx-auto">
                <h1>Sitemap</h1>
                <h3>Get around our website 🔍</h3>
                <div className="row">
                <ul className="ul-col-1">
                <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about" >About Us</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                    <ul>

                    <li>
                    <Link to="/services">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/services/#mobile-service-scroll">Mobile Solutions</Link>
                  </li>
                  <li>
                    <Link to="/services/#cloud-service-scroll">Cloud Solutions</Link>
                  </li>
                  <li>
                    <Link to="/services/#devops-service-scroll">DevOps Solutions</Link>
                  </li>
                  <li>
                    <Link to="/services/#ai-service-scroll">AI Solutions</Link>
                  </li>
                  <li>
                    <Link to="/services/#blockchain-service-scroll">Blockchain Solutions</Link>
                  </li>
                  <li>
                    <Link to="/services/#iot-service-scroll">IoT Solutions</Link>
                  </li>
                  <li>
                    <Link to="/services/#bigdata-service-scroll">Bigdata Solutions</Link>
                  </li>
                  <li>
                    <Link to="/services/#startup-service-scroll">Startup Solutions</Link>
                  </li>

                    </ul>
                  </li>
                  <li>
                    <Link to="/career">Career</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
                </div>
        </div>
        </div>
            </div>
        </div>
    )
}

export default Sitemap
